import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const CallToAction = loadable(() => import('/src/components/PageBuilder/layouts/standard/CallToAction'))

const HelpCta = ({ backgroundColour }) => {
    return (
        <CallToAction
            backgroundColour={backgroundColour}
            titleArea={{
                addTitleArea: true,
                titlePosition: 'centered',
                title: `Still can't find what you're looking for?`,
                description: `Our Help Centre is the quickest and easiest way for you to get an answer to your query. However, if you haven't found the answer to your question here, or you'd like to get in touch about something else, you can also contact our Customer Service Support Team who will be happy to assist.`
            }}
            addCta={true}
            callToAction={{
                url: '/contact-us',
                title: 'Contact Protect Line'
            }}
        />
    )
}

HelpCta.propTypes = {
    backgroundColour: PropTypes.string
}

export default HelpCta
